// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/app/src/models/global';
import model_2 from '/app/src/pages/POS/models/order';
import model_3 from '/app/src/pages/POS/models/setting';
import model_4 from '/app/src/pages/Product/models/update';
import model_5 from '/app/src/.umi-production/plugin-initialState/@@initialState';

export const models = {
model_1: { namespace: 'global', model: model_1 },
model_2: { namespace: 'POS.order', model: model_2 },
model_3: { namespace: 'POS.setting', model: model_3 },
model_4: { namespace: 'Product.update', model: model_4 },
model_5: { namespace: '@@initialState', model: model_5 },
} as const
