import { currentUser } from '@/services/AuthController';
import { LogoutOutlined, ShopOutlined } from '@ant-design/icons';
import { ProBreadcrumb } from '@ant-design/pro-components';
import { history } from '@umijs/max';
import { Button, Dropdown } from 'antd';
import './global.less';
import useAuth from './hooks/useAuth';

export async function getInitialState(): Promise<{
  currentUser?: API.CurrentUser;
}> {
  try {
    const { data } = await currentUser();

    return { currentUser: data };
  } catch (error) {
    history.push('/login');
    return {};
  }
}

export const layout = () => {
  /* eslint-disable-next-line */
  const { currentUser, logout } = useAuth();

  return {
    title: 'ZenPOS',
    menu: {
      locale: false,
    },
    avatarProps: {
      src: 'https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg',
      size: 'small',
      title: currentUser?.name,
      render: (_props: any, dom: any) => {
        return (
          <Dropdown
            menu={{
              items: [
                {
                  key: 'logout',
                  icon: <LogoutOutlined />,
                  label: 'Đăng xuất',
                  onClick: logout,
                },
              ],
            }}
          >
            {dom}
          </Dropdown>
        );
      },
    },
    actionsRender: () => [
      <Button
        type="link"
        key={'pos'}
        icon={<ShopOutlined />}
        size="small"
        onClick={() => history.push('/pos')}
      >
        Bán hàng
      </Button>,
    ],
    menuFooterRender: (props: any) => {
      if (props?.collapsed) return undefined;
      return (
        <div
          style={{
            textAlign: 'center',
            paddingBlockStart: 12,
          }}
        >
          <div>© 2024 ZenPOS</div>
        </div>
      );
    },
    layout: 'mix',
    headerContentRender: () => <ProBreadcrumb />,
  };
};
