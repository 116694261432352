import { getAttributes } from '@/services/AttributeController';
import { getProduct } from '@/services/ProductController';
import { useState } from 'react';

export default function Page() {
  const [product, setProduct] = useState({});
  const [attributes, setAttributes] = useState({});
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchProduct = async (productId: string) => {
    try {
      const resp = await getProduct(productId);
      const { data } = await getAttributes();

      setProduct(resp.data);
      setAttributes(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    product,
    attributes,
    fetchProduct,
    changed,
    setChanged,
    loading,
  };
}
