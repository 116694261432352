// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"name":"Đăng nhập","path":"/login","layout":false,"id":"1"},"2":{"path":"/pos","name":"Bán hàng","icon":"HomeOutlined","layout":false,"id":"2"},"3":{"path":"/pos/orders","name":"Tra cứu đơn hàng","icon":"HomeOutlined","layout":false,"id":"3"},"4":{"path":"/pos/settings","name":"Cấu hình","icon":"HomeOutlined","layout":false,"id":"4"},"5":{"path":"/","redirect":"/dashboard","parentId":"ant-design-pro-layout","id":"5"},"6":{"path":"","name":"Admin","flatMenu":true,"parentId":"7","id":"6","originPath":"/"},"7":{"path":"/","isWrapper":true,"parentId":"ant-design-pro-layout","id":"7"},"8":{"path":"dashboard","name":"Tổng quan","icon":"HomeOutlined","parentId":"6","id":"8"},"9":{"path":"products","name":"Sản phẩm","icon":"ProductOutlined","parentId":"6","id":"9"},"10":{"path":"/products","redirect":"/products/list","parentId":"9","id":"10"},"11":{"path":"/products/list","name":"Danh sách sản phẩm","parentId":"9","id":"11"},"12":{"path":"/products/create","name":"Thêm mới sản phẩm","hideInMenu":true,"parentId":"9","id":"12"},"13":{"path":"/products/:id","name":"Cập nhật sản phẩm","hideInMenu":true,"parentId":"9","id":"13"},"14":{"path":"/products/category","name":"Nhóm sản phẩm","parentId":"9","id":"14"},"ant-design-pro-layout":{"id":"ant-design-pro-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import(/* webpackChunkName: "p__Auth__Login" */'@/pages/Auth/Login.tsx')),
'2': React.lazy(() => import(/* webpackChunkName: "p__POS__index" */'@/pages/POS/index.tsx')),
'3': React.lazy(() => import(/* webpackChunkName: "p__POS__Order__index" */'@/pages/POS/Order/index.tsx')),
'4': React.lazy(() => import(/* webpackChunkName: "p__POS__Setting__index" */'@/pages/POS/Setting/index.tsx')),
'5': React.lazy(() => import('./EmptyRoute')),
'6': React.lazy(() => import('./EmptyRoute')),
'7': React.lazy(() => import(/* webpackChunkName: "wrappers__AuthWrapper" */'@/wrappers/AuthWrapper.tsx')),
'8': React.lazy(() => import(/* webpackChunkName: "p__Dashboard__index" */'@/pages/Dashboard/index.tsx')),
'9': React.lazy(() => import('./EmptyRoute')),
'10': React.lazy(() => import('./EmptyRoute')),
'11': React.lazy(() => import(/* webpackChunkName: "p__Product__index" */'@/pages/Product/index.tsx')),
'12': React.lazy(() => import(/* webpackChunkName: "p__Product__Create" */'@/pages/Product/Create.tsx')),
'13': React.lazy(() => import(/* webpackChunkName: "p__Product__Update" */'@/pages/Product/Update.tsx')),
'14': React.lazy(() => import(/* webpackChunkName: "p__Dashboard__index" */'@/pages/Dashboard/index.tsx')),
'ant-design-pro-layout': React.lazy(() => import(/* webpackChunkName: "t__plugin-layout__Layout" */'/app/src/.umi-production/plugin-layout/Layout.tsx')),
},
  };
}
