/* eslint-disable */
import request from '@/services/request';

export async function getAttributes() {
  return request('/api/attributes', {
    method: 'GET',
  });
}

export async function createtAttribute(data: {
  name: string;
  values: string[];
}) {
  return request('/api/attributes', {
    method: 'POST',
    data,
  });
}
