/* eslint-disable */
import request from '@/services/request';

export async function getProducts(params: {
  pageSize?: number;
  current?: number;
  keyword?: string;
  filters?: Record<string, any[]>;
  sorter?: Record<string, any>;
}) {
  return request('/api/products', {
    method: 'GET',
    params,
  });
}

export async function getProduct(id: string) {
  return request(`/api/products/${id}`);
}

export async function createProduct(data: any) {
  return await request('/api/products', {
    method: 'POST',
    data,
  });
}

export async function findProductByBarcode(barcode: string) {
  return request(`/api/products/barcode/${barcode}`);
}
