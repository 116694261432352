/* eslint-disable */
import request from '@/services/request';

export async function getCsrfCookie() {
  return request('/api/csrf-cookie', {
    method: 'GET',
  });
}

export async function login(data: { email: string; password: string }) {
  // await getCsrfCookie();
  return await request('/api/login', {
    method: 'POST',
    data,
  });
}

export async function logout() {
  return request('/api/logout', {
    method: 'POST',
  });
}
export async function currentUser() {
  return request('/api/user');
}
