import { RequestConfig, request as umiRequest } from 'umi';

const request = async (url: string, options: RequestConfig = {}) => {
  const token = localStorage.getItem('authToken');

  if (token) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    };
  }

  return umiRequest(url, options);
};

export default request;
