import {
  login as RequestLogin,
  logout as RequestLogout,
} from '@/services/AuthController';
import { history, useModel } from '@umijs/max';

const useAuth = () => {
  const { initialState, loading, setInitialState } = useModel('@@initialState');
  const { setStore } = useModel('POS.setting');

  const isLoggedIn =
    !!initialState?.currentUser || !!localStorage.getItem('authToken');

  const login = async (values: any) => {
    const { data }: any = await RequestLogin(values);
    localStorage.setItem('authToken', data.token);
    setInitialState({ ...initialState, currentUser: data });
    setStore(data.store);

    history.replace('/');
  };

  const logout = async () => {
    await RequestLogout();
    setInitialState({ ...initialState, currentUser: undefined });
    localStorage.clear();
    history.push('/login');
  };

  return {
    isLoggedIn,
    loading,
    currentUser: initialState?.currentUser,
    login,
    logout,
  };
};

export default useAuth;
