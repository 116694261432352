import { useLocalStorageState } from 'ahooks';

export default function Page() {
  const [activeKey, setActiveKey] = useLocalStorageState('activeKey', {
    defaultValue: '1',
  });
  const [products, setProducts] = useLocalStorageState(
    `products-${activeKey}`,
    {
      defaultValue: [],
    },
  );
  // const [products, setProducts] = useState([]);
  const [customer, setCustomer] = useLocalStorageState(`customer-${activeKey}`);
  const [priceType, setPriceType] = useLocalStorageState(
    `priceType-${activeKey}`,
    { defaultValue: 'retail_price' },
  );
  const [orders, setOrders] = useLocalStorageState('orders', {
    defaultValue: [
      {
        label: 'Đơn 1',
        key: '1',
        closable: false,
      },
    ],
  });

  return {
    products,
    setProducts,
    orders,
    setOrders,
    activeKey,
    setActiveKey,
    customer,
    setCustomer,
    priceType,
    setPriceType,
  };
}
