// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';
import {
  defaultContext,
  QueryClient,
  QueryClientProvider
} from '/app/node_modules/@umijs/plugins/node_modules/@tanstack/react-query';
import { ReactQueryDevtools } from '/app/node_modules/@umijs/plugins/node_modules/@tanstack/react-query-devtools';
const reactQueryConfig = {};
const client = new QueryClient(reactQueryConfig.queryClient || {});
export function rootContainer(container) {
  return (
    <QueryClientProvider
      client={client}
      context={defaultContext}
    >
      {container}
      <ReactQueryDevtools
  context={defaultContext}
  initialIsOpen={false}
  {...(reactQueryConfig.devtool || {})}
/>
    </QueryClientProvider>
  );
}
