import { useLocalStorageState } from 'ahooks';

export default function Page() {
  const [store, setStore] = useLocalStorageState('store');

  return {
    store,
    setStore,
  };
}
