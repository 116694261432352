import { currentStore } from '@/services/StoreController';
import { useLocalStorageState } from 'ahooks';

export default function Page() {
  const [store, setStore] = useLocalStorageState('store', {
    defaultValue: async () => {
      const { data } = await currentStore();
      setStore(data);
      return data;
    },
  });

  return {
    store,
    setStore,
  };
}
